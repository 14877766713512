import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../components/common';
import JusticeBook from '../images/icons/186325-justice/svg/book.svg';

const LawsAndRegulationsPage = () => (
  <Layout>
    <Helmet>
      <title>Rent Laws and Regulations</title>
      <meta
        name="description"
        content="Renting is a major financial decision. It's important to know what laws and regulations there are to protect yourself and rent smarter."
      />
    </Helmet>
    <section className="hero is-black">
      <div className="hero-body">
        <div className="container content">
          <div className="columns is-centered is-vcentered">
            <div className="column is-narrow">
              <figure className="image is-128x128 has-mv-0 is-centered">
                <img className="is-rounded" src={JusticeBook} />
              </figure>
            </div>
            <div className="column is-6">
              <h1 className="title is-1 has-text-centered-mobile has-text-left is-marginless">
                Rent Laws and Regulations
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" id="introduction">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="content">
              <h2 className="is-size-4 has-mt-0 has-pv-5 has-mb-5 has-text-centered">Introduction</h2>
              <p>
                Have you ever wondered if an application fee has a maximum? Or if a clause in a lease is sensible? Any
                one has ever rented, you probably have.
              </p>
              <p>
                It&apos;s important to be aware of the body of laws that regulate renting. There are rules that regulate
                how tenants are chosen, buildings are maintained, leases are negotiated, and more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" id="federal">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="content">
              <h2 className="is-size-4 has-mt-0 has-pv-5 has-mb-5 has-text-centered">General and Federal Laws</h2>
              <p>There are some laws that exist in nearly every state and some that are national.</p>
              <ul>
                <li>
                  <strong>Implied Warranty of Habitability</strong>: You are entitled to a safe and livable residences.
                  This involves the basics: a roof, access to hot water, a heater, walls, absense of danger, and absence
                  of vermin.
                </li>
                <li>
                  <strong>Duty to Mitigate Damages</strong>: Property managers are required to mitigate damages for
                  tenants. So, if you break your lease, your landlord is required to look for someone new to fill the
                  vacancy. Similarly, if you find a new, qualified tenant for your property manager, they are required
                  to fill the vacancy.
                </li>
                <li>
                  <strong>Privacy</strong>: Tenant&apos;s have a right to privacy that vary on a state basis. Typically,
                  landlords can&apos;t come into your place of residence without prior notice. Also, some types of
                  information that property managers gather are protected and should not be shared.
                </li>
                <li>
                  <strong>Fair Housing Act</strong>: Tenant&apos;s cannot be discriminated against on the basis of race,
                  color, national origin, religion, sex, family status, and disability.
                </li>
                <li>
                  <strong>Fair Credit Reporting Act</strong>: If a property manager does a credit or background check,
                  you have the right to get a copy and dispute inaccuracies.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default LawsAndRegulationsPage;
